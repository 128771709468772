<template>
  <en-table-layout :pagination="false" :tableData="pageData.data" :loading="loading">
    <template slot="toolbar">
      <div class="toolbar-title">

      </div>
    </template>

    <template slot="table-columns">
      <el-table-column prop="name" label="公司名称"/>
      <el-table-column prop="code" label="公司代码"/>
      <el-table-column prop="kdcode" label="快递鸟代码"/>
      <!-- <el-table-column label="是否启用">
        <template slot-scope="scope">
          <el-switch
            @change="handleLogisticsSwitch(scope.row)"
            v-model="!!scope.row.shop_id"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column> -->
    </template>
    <!--<el-pagination v-if="tableData" slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]" :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper" :total="tableData.data_total">
    </el-pagination>-->
  </en-table-layout>
</template>

<script>
import * as API_Payment from '@/api/shopSetting'
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'companyList',
  components: {EnTableLayout},
  data() {
    return {
      pageData: {data: []},
      params: {},
      loading: false,
      value: true
    }
  },
  mounted() {
  },
  created() {
    this.GET_CompanyList();
  },
  methods: {
    /** 物流公司信息开启 /关闭 */
    handleLogisticsSwitch(row) {

      const _tip = row.shop_id ? '关闭' : '开启'
      this.$confirm(`确定要${_tip}么?`, '确认信息').then(() => {
        row.shop_id ? this.closeSwitch(row) : this.openSwitch(row)
      })

    },

    GET_CompanyList() {
      this.loading = true
      API_Payment.getPaymentList(this.params).then(response => {
        this.loading = false
        this.pageData.data = response;
        // console.log(response, 'response')
      }).catch(() => {
        this.loading = false
      })
    },
    openSwitch(row) {
      var self = this;
      self.$set(row, 'shop_id', '');
      API_Payment.openSwitch(row.logi_id).then(response => {
        row.shop_id = response.seller_id;

        // console.log(response, 'response')
      }).catch(() => {
        this.loading = false
      })
    },
    closeSwitch(row) {
      API_Payment.closeSwitch(row.logi_id).then(response => {
        row.shop_id = '';
        // console.log(response, 'response')
      }).catch(() => {
        this.loading = false
      })
    }

  }
}
</script>

<style lang="scss" scoped>
</style>
